import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=23b11841&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=23b11841&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b11841",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VCard,VContainer,VDialog,VExpandXTransition,VIcon,VList})
